<template>
  <div>
    <div style="height:50px">
    </div>
    <v-container fluid >
      <v-layout wrap row>
        <v-flex xs12 sm12 md12 class="pb-3">
          <v-row ref="c1" >
            <v-col justify="start" align="center">
              <v-select
                  :loading="isLoadingPhylum"
                  :items="selectSpeciesAllList.phylumList"
                  label="① Select Phylum"
                  @input="selectPhylum"
                  style="width: 250px"
              ></v-select>
            </v-col>
          </v-row>
        </v-flex>

        <v-flex xs12 sm12 md12 class="pb-3" style="height: 30px">
        </v-flex>

        <v-flex xs12 sm12 md5 class="pb-3">
          <v-card :height="cardSize.height" loading="false"  :max-width="chartSize.width" :width="chartSize.width" >
            <div style="height:15px"></div>
            <v-row ref="c1" justify="start" align="center">
              <v-col justify="start" align="center">
                <v-autocomplete
                    :loading="isLoadingSampleData1"
                    label="② Sample Code 1"
                    placeholder="Select Sample Code"
                    :items="codeList"
                    dense
                    style="width: 250px"
                    @input="selectedCode1"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row ref="c1" justify="start" align="center">
              <v-col cols="auto">
                <v-skeleton-loader v-if="isSkeletonLoader"
                    boilerplate
                    type="article, image, divider, article, image, divider, article, image, divider"
                ></v-skeleton-loader>
                <v-chart :options="sampleData1.chartData" :initOptions="chartSize" />
              </v-col>
            </v-row>
            <div :style="{height: spaceHeight}"></div>
            <v-row justify="start" align="center">
              <v-col cols="auto">
                <v-chart :options="sampleData1.freqDistributionChartData" :initOptions="chartSize" />
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
        <v-flex mx12 sm12 md5 offset-md2 class="pb-3">
          <v-card :height="cardSize.height" loading="false" :max-width="chartSize.width" :width="chartSize.width" >
            <div style="height:15px"></div>
            <v-row ref="c1" justify="start" align="center">
              <v-col justify="start" align="center">
                <v-autocomplete
                    :loading="isLoadingSampleData2"
                    label="③ Sample Code 2"
                    placeholder="Select Sample Code2"
                    :items="codeList"
                    dense
                    style="width: 250px"
                    @input="selectedCode2"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row ref="c1" justify="start" align="center">
              <v-col cols="auto">
                <v-skeleton-loader v-if="isSkeletonLoader"
                                   boilerplate
                                   type="article, image, divider, article, image, divider, article, image, divider"
                ></v-skeleton-loader>
                <v-chart :options="sampleData2.chartData" :initOptions="chartSize" />
              </v-col>
            </v-row>
            <div :style="{height: spaceHeight}"></div>
            <v-row justify="start" align="center">
              <v-col cols="auto">
                <v-chart :options="sampleData2.freqDistributionChartData" :initOptions="chartSize" />
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'TrandSampleCompare',
  data() {
    return {
      isLoadingPhylum: false,
      isLoadingSampleData1: false,
      isLoadingSampleData2: false,
      loading : true,
      isSkeletonLoader: true,
      cardSize : { height: 1100 },
      chartSize : { width: 600, height: 500 },
      spaceHeight: "80px",
      // Notice : 선택된 데이터 비교를 배열 처리 하려 했으나 Template 로딩시 배열 안의 Object chart 의 데이터 구조 초기화 이슈로 분리
      sampleData1: {},
      sampleData2: {},
      compareCodeList: ["", ""],
      selectedPhylum: "",
      phylumDataList: null,
      // 키 데이터 상수 (this.sampleData1, this.sampleData2)
      SAMPLE_DATA_STR1: "sampleData1",
      SAMPLE_DATA_STR2: "sampleData2"

    }

  },
  methods: {
    ...mapActions(['getByAgeDiversityPhylum', 'getSelectList', 'getByAgeDiversityPhylumDataList', 'getByAgeDiversityPhylumChartData']),
    selectPhylum : async function(phylum) {

      if (this.selectedPhylum === phylum) return;
      this.isLoadingPhylum = true;
      this.selectedPhylum = phylum;
      this.isSkeletonLoader = true;
      this.phylumDataList = await this.getByAgeDiversityPhylumDataList(phylum);
      //console.log(this.phylumDataList)
      if (this.phylumDataList === null){
        alert("Phylum List 를 가져오는데 실패 하였습니다.");
        this.isLoadingPhylum = false;
        return;
      }

      await this.drawPhylumChartDate();

      this.isLoadingPhylum = false;
      this.isSkeletonLoader = false;

    },
    drawPhylumChartDate : async function(){
      this.compareCodeList.reduce( (acc, code, index) => {
        console.log("key %s, %s, %s", acc, code, index);
        this["selectedCode" + (index + 1)](code);
        return acc;
      }, 0)
    },
    changeChartPointByCode: async function(sampleStr, code){
      this.switchLoading(sampleStr, true);
      console.log("[trandSampleCompare][changeChartPointByCode] sampleStr=%s, code=%s", sampleStr, code);
      this[sampleStr] = await this.getByAgeDiversityPhylumChartData(
          { code: code, phylumDataList: this.phylumDataList }
      );
      this.switchLoading(sampleStr, false);
    },
    switchLoading: function(sampleStr, isLoading = false){
      switch(sampleStr){
        case this.SAMPLE_DATA_STR1:
          this.isLoadingSampleData1 = isLoading;
          break;
        case this.SAMPLE_DATA_STR2:
          this.isLoadingSampleData2 = isLoading;
          break;
        default:
          break;
      }
    },
    selectedCode1: function(code) {
      this.compareCodeList[0] = code;
      if (this.phylumDataList === null) return;
      this.changeChartPointByCode(this.SAMPLE_DATA_STR1, code);
    },
    selectedCode2: function(code) {
      this.compareCodeList[1] = code;
      if (this.phylumDataList === null) return;
      this.changeChartPointByCode(this.SAMPLE_DATA_STR2, code);
    }
  },
  mounted() {
    if(this.selectSpeciesAllList.phylumList.length <= 0){
      this.getSelectList();
    }
  },
  computed : {
    selectSpeciesAllList() {
      return this.$store.state.selectList;
    },
    codeList() {
      return this.$store.state.codeList;
    }
  }

}
</script>

<style scoped>

</style>